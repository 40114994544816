package net.gorillagroove.discovery

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import net.gorillagroove.api.Api
import net.gorillagroove.api.UserId
import net.gorillagroove.api.YoutubeDownloadServerId
import net.gorillagroove.user.User
import net.gorillagroove.user.UserService
import net.gorillagroove.user.permission.PermissionService
import net.gorillagroove.user.permission.UserPermissionType
import net.gorillagroove.util.Formatter.toTimeAgoString

object DownloadServerService {
    suspend fun getServers(): List<DownloadServer> {
        val servers = Api.get<DownloadServerResponse>("youtube-download-server").servers

        val canEditAnyServer = PermissionService.hasPermission(UserPermissionType.ADMIN_YT_DL_SERVERS)

        val users = UserService.findByIds(servers.map { it.ownerId })
            .associateBy { it.id }

        val ownUserId = UserService.getCurrentUserId()

        return servers.map { server ->
            server.toNormalizedData(
                user = users[server.ownerId],
                editable = canEditAnyServer || server.ownerId == ownUserId
            )
        }
    }

    suspend fun createServer(
        name: String,
        serverAddress: String,
        receiveAlerts: Boolean,
        enabled: Boolean,
    ): DownloadServer {
        val request = CreateDownloadServerRequest(
            name = name,
            address = serverAddress,
            receiveAlerts = receiveAlerts,
            enabled = enabled,
        )

        return Api.post<ApiDownloadServer>("youtube-download-server", request).toNormalizedData()
    }

    suspend fun updateServer(
        id: YoutubeDownloadServerId,
        serverAddress: String? = null,
        name: String? = null,
        receiveAlerts: Boolean? = null,
        enabled: Boolean? = null,
    ): DownloadServer {
        val request = UpdateDownloadServerRequest(
            name = name,
            address = serverAddress,
            receiveAlerts = receiveAlerts,
            enabled = enabled,
        )

        return Api.patch<ApiDownloadServer>("youtube-download-server/${id.value}", request).toNormalizedData()
    }

    suspend fun deleteServer(id: YoutubeDownloadServerId) {
        return Api.delete("youtube-download-server/${id.value}")
    }

    suspend fun pingServer(id: YoutubeDownloadServerId): Boolean {
        return Api.post<SuccessResponse>("youtube-download-server/ping/${id.value}").success
    }

    suspend fun pingServer(serverAddress: String): Boolean {
        val request = TestPingRequest(serverAddress)

        return Api.post<SuccessResponse>("youtube-download-server/ping", request).success
    }

    suspend fun requestVersionInfo(id: YoutubeDownloadServerId): RemoteServerVersionResponse {
        return Api.post<RemoteServerVersionResponse>("youtube-download-server/version/${id.value}")
    }

    suspend fun testDownload(id: YoutubeDownloadServerId): Boolean {
        return Api.post<SuccessResponse>("youtube-download-server/test-download/${id.value}").success
    }

    suspend fun getLogs(id: YoutubeDownloadServerId): LogResponse {
        return Api.get("youtube-download-server/logs/${id.value}")
    }
}

@Serializable
private data class ApiDownloadServer(
    val id: YoutubeDownloadServerId,
    val ownerId: UserId,
    val name: String,
    val serverAddress: String?,
    val consecutiveDownloadErrors: Int,
    val consecutivePingErrors: Int,
    val serverVersion: Int,
    val downloaderVersion: String,
    val enabled: Boolean,
    val receiveAlerts: Boolean,
    val lastSuccessfulPing: Instant?,
    val lastSuccessfulDownload: Instant?,
    val lastDownloadAttempt: Instant?,
) {
    fun toNormalizedData(
        user: User? = UserService.findById(ownerId),
        editable: Boolean = true,
    ) = DownloadServer(
        id = id,
        ownerId = ownerId,
        ownerName = user?.name ?: "Unknown",
        editable = editable,
        name = name,
        serverAddress = serverAddress,
        consecutiveDownloadErrors = consecutiveDownloadErrors,
        consecutivePingErrors = consecutivePingErrors,
        enabled = enabled,
        receiveAlerts = receiveAlerts,
        serverVersion = serverVersion,
        downloaderVersion = downloaderVersion,
        lastDownloadAttempt = lastDownloadAttempt,
        lastDownloadAttemptString = lastDownloadAttempt?.toTimeAgoString() ?: "",
        lastSuccessfulDownload = lastSuccessfulDownload,
        lastSuccessfulDownloadString = lastSuccessfulDownload?.toTimeAgoString() ?: "",
        lastSuccessfulPing = lastSuccessfulPing,
        lastSuccessfulPingString = lastSuccessfulPing?.toTimeAgoString() ?: "",
    )
}

data class DownloadServer(
    val id: YoutubeDownloadServerId,
    val ownerId: UserId,
    val ownerName: String,
    val editable: Boolean,
    val name: String,
    val serverAddress: String?,
    val consecutiveDownloadErrors: Int,
    val consecutivePingErrors: Int,
    val enabled: Boolean,
    val receiveAlerts: Boolean,
    val serverVersion: Int,
    val downloaderVersion: String,
    val lastSuccessfulPing: Instant?,
    val lastSuccessfulPingString: String,
    val lastSuccessfulDownload: Instant?,
    val lastSuccessfulDownloadString: String,
    val lastDownloadAttempt: Instant?,
    val lastDownloadAttemptString: String,
)

@Serializable
private data class DownloadServerResponse(val servers: List<ApiDownloadServer>)

@Serializable
internal data class CreateDownloadServerRequest(val name: String, val address: String, val receiveAlerts: Boolean, val enabled: Boolean)

@Serializable
internal class UpdateDownloadServerRequest(val name: String?, val address: String?, val receiveAlerts: Boolean?, val enabled: Boolean?)

@Serializable
internal class TestPingRequest(val address: String)

@Serializable
internal class SuccessResponse(val success: Boolean)

@Serializable
data class RemoteServerVersionResponse(val downloaderVersion: String, val serverVersion: Int)

@Serializable
data class LogResponse(val logs: String)
