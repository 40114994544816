package components

import kotlinx.browser.document
import kotlinx.coroutines.launch
import kotlinx.html.*
import kotlinx.html.dom.append
import kotlinx.html.dom.create
import kotlinx.html.js.div
import kotlinx.html.js.onChangeFunction
import mainScope
import net.gorillagroove.api.UserId
import net.gorillagroove.history.SiteStats
import net.gorillagroove.history.UserStatsResponse
import net.gorillagroove.user.UserService
import net.gorillagroove.user.permission.PermissionService
import net.gorillagroove.user.permission.UserPermissionType
import org.jetbrains.letsPlot.Stat
import org.jetbrains.letsPlot.frontend.JsFrontendUtil
import org.jetbrains.letsPlot.geom.geomBar
import org.jetbrains.letsPlot.geom.geomPie
import org.jetbrains.letsPlot.intern.Plot
import org.jetbrains.letsPlot.label.ggtitle
import org.jetbrains.letsPlot.letsPlot
import org.jetbrains.letsPlot.themes.elementBlank
import org.jetbrains.letsPlot.themes.theme
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLSelectElement
import query
import queryId
import kotlin.math.roundToInt
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds

private fun isAdmin() = PermissionService.hasPermission(UserPermissionType.VIEW_ALL_YEAR_END_SUMMARIES)

@Suppress("FunctionName")
fun UserYearInReview(): HTMLDivElement {
    return document.create.div {
        id = "user-stats"

        if (isAdmin()) {
            select {
                UserService.findAll().forEach { user ->
                    option {
                        +user.name

                        value = user.id.value.toString()
                    }
                }

                onChangeFunction = { event ->
                    val select = event.currentTarget as HTMLSelectElement
                    setStuff(UserId(select.value.toLong()))
                }
            }
        }

        h1 { }

        div("space-around") {
            id = "stuff"
        }

        mainScope.launch {
            setStuff(UserService.requireCurrentUserId())
        }
    }
}

private fun setStuff(userId: UserId) = mainScope.launch {
    val stats = SiteStats.getUserStats(userId)

    val user = UserService.findById(userId)!!

    val usernameField = document.query<HTMLElement>("#user-stats h1")
    usernameField.innerText = "${user.name}'s 2024 Grooving"

    val stuffContainer = document.queryId<HTMLElement>("stuff")

    stuffContainer.innerHTML = ""

    stuffContainer.append {
        div("space-around") {
            div("section") {
                h3 {
                    +"Top songs"
                }

                table {
                    stats.mostListenedTracks.forEach { (track, plays) ->
                        tr {
                            td("text-left first") {
                                +track
                            }
                            td("text-right") {
                                + "$plays plays"
                            }
                        }
                    }
                }
            }

            div("section") {
                h3 {
                    +"Top artists"
                }

                table {
                    stats.mostListenedArtists.forEach { (artist, plays) ->
                        tr {
                            td("text-left first") {
                                +artist
                            }
                            td("text-right") {
                                + "$plays plays"
                            }
                        }
                    }
                }
            }
        }

        div("mt-12 space-around") {
            div("section") {
                h3 {
                    +"Top albums"
                }

                table {
                    stats.mostListenedAlbums.forEach { (album, plays) ->
                        tr {
                            td("text-left first") {
                                +album
                            }
                            td("text-right") {
                                + "$plays plays"
                            }
                        }
                    }
                }
            }
            div("section") {
                h3 {
                    +"Other Stats"
                }
                table {
                    tr {
                        td("text-left first") {
                            +"Total time listened"
                        }
                        td("text-right") {
                            + "${stats.totalTimeListened.seconds.inWholeHours} hours"
                        }
                    }

                    if (stats.distanceTraveled > 0) {
                        td("text-left first") {
                            +"Total distance traveled"
                        }
                        td("text-right") {
                            + "${(stats.distanceTraveled / 1000).roundToInt()} km"
                        }
                    }
                }
            }
        }
    }

    stuffContainer.appendChild(createDayPlot(stats))
    stuffContainer.appendChild(createMonthPlot(stats))
    stuffContainer.appendChild(createHourPlot(stats))
    stuffContainer.appendChild(createDeviceTypePlot(stats))
}

private fun createDayPlot(stats: UserStatsResponse): HTMLDivElement {
    val data = mapOf(
        "Day of Week" to listOf("Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"),
        "Hours Listened" to stats.timeListeningByDay.map { it.seconds.toGraphUnit() },
    )

    val plot = letsPlot(data) {
        x = "Day of Week"
        y = "Hours Listened"
    } + geomBar(stat = Stat.identity)

    return createPlotDiv(plot)
}

private fun createMonthPlot(stats: UserStatsResponse): HTMLDivElement {
    val data = mapOf(
        "Month of Year" to listOf("Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"),
        "Hours Listened" to stats.timeListeningByMonth.map { it.seconds.toGraphUnit() },
    )

    val plot = letsPlot(data) {
        x = "Month of Year"
        y = "Hours Listened"
    } + geomBar(stat = Stat.identity)

    return createPlotDiv(plot)
}

private fun createHourPlot(stats: UserStatsResponse): HTMLDivElement {
    val data = mapOf(
        "Hour of Day" to listOf("12AM", "1AM", "2AM", "3AM", "4AM", "5AM", "6AM", "7AM", "8AM", "9AM", "10AM", "11AM", "12PM", "1PM", "2PM", "3PM", "4PM", "5PM", "6PM", "7PM", "8PM", "9PM", "10PM", "11PM"),
        "Hours Listened" to stats.timeListeningByHour.map { it.seconds.toGraphUnit() },
    )

    val plot = letsPlot(data) {
        x = "Hour of Day"
        y = "Hours Listened"
    } + geomBar(stat = Stat.identity)

    return createPlotDiv(plot)
}

private fun createDeviceTypePlot(stats: UserStatsResponse): HTMLDivElement {
    val data = mapOf(
        "Device Types" to stats.timeListenedByDeviceType.keys.map { it.displayName },
        "Hours Listened" to stats.timeListenedByDeviceType.map { it.value.seconds.toGraphUnit() },
    )

    val plot = letsPlot(data) + geomPie(stat = Stat.identity, size = 25) {
        fill = "Device Types"
        slice = "Hours Listened"
    } + theme(
        axisLine = elementBlank(),
        axisTitle = elementBlank(),
        axisTicks = elementBlank(),
        axisText = elementBlank(),
        axisTextX = elementBlank(),
        axisTextY = elementBlank(),
        stripBackground = elementBlank(),
        panelGrid = elementBlank(),
    ) + ggtitle(title = "Hours by Device Type")

    return createPlotDiv(plot)
}

// Mostly for messing around with local development. But I might change this to return "Days" later idk.
private fun Duration.toGraphUnit(): Long {
    return this.inWholeHours
}

private fun createPlotDiv(plot: Plot): HTMLDivElement {
    return JsFrontendUtil.createPlotDiv(plot).also {
        it.classList.add("mt-12", "auto-side-margins")
    }
}
