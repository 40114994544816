@file:Suppress("FunctionName")

package components.contextmenu

import Dialog
import Toast
import components.*
import kotlinx.browser.document
import kotlinx.coroutines.launch
import kotlinx.html.*
import kotlinx.html.dom.create
import kotlinx.html.js.div
import kotlinx.html.js.onClickFunction
import mainScope
import net.gorillagroove.track.MetadataOverrideType
import net.gorillagroove.track.MetadataService
import net.gorillagroove.track.Track
import net.gorillagroove.util.Formatter
import net.gorillagroove.util.GGLog
import net.gorillagroove.util.GGLog.logError
import org.w3c.dom.HTMLInputElement
import query
import queryId

fun MetadataUpdateRequestMenu(tracks: List<Track>) = document.create.div {
    id = "metadata-update-request-menu"

    val settings = listOf(
        "Album",
        "Album Art",
        "Release Year",
        "Track Number",
    )

    fun setAll(type: MetadataOverrideType) {
        settings.forEach { setting ->
            document.queryId<HTMLInputElement>("metadata-$setting-${type.name}").checked = true
        }
    }

    fun getValue(settingName: String): MetadataOverrideType {
        val value = document.query<HTMLInputElement>("input[name=\"metadata-$settingName\"]:checked").value
        return MetadataOverrideType.valueOf(value)
    }

    h2 { + "Request Metadata" }

    div {
        + "Take the following pieces of data:"
    }

    hr {  }

    table {
        thead {
            tr {
                th { }
                th {
                    span("ml-8 mr-8") {
                        button(classes = "flat slim") {
                            +"Always"

                            tooltip = "The existing metadata will be replaced if any new metadata is found"

                            onClickFunction = { setAll(MetadataOverrideType.ALWAYS) }
                        }
                    }
                }
                th {
                    span("ml-8 mr-8") {
                        button(classes = "flat slim") {
                            +"If Empty"

                            tooltip = "Any found metadata will not overwrite existing data"

                            onClickFunction = { setAll(MetadataOverrideType.IF_EMPTY) }
                        }
                    }
                }
                th {
                    span("ml-8 mr-8") {
                        button(classes = "flat slim") {
                            +"Never"

                            tooltip = "Do not use any found metadata"

                            onClickFunction = { setAll(MetadataOverrideType.NEVER) }
                        }
                    }
                }
            }
        }

        tbody {
            settings.forEach { rowName ->
                tr {
                    td("text-left") { + rowName }
                    td {
                        input(InputType.radio) {
                            id = "metadata-$rowName-ALWAYS"
                            name = "metadata-$rowName"
                            value = MetadataOverrideType.ALWAYS.name
                            checked = false
                        }
                    }
                    td {
                        input(InputType.radio) {
                            id = "metadata-$rowName-IF_EMPTY"
                            name = "metadata-$rowName"
                            value = MetadataOverrideType.IF_EMPTY.name
                            checked = true
                        }
                    }
                    td {
                        input(InputType.radio) {
                            id = "metadata-$rowName-NEVER"
                            name = "metadata-$rowName"
                            value = MetadataOverrideType.NEVER.name
                            checked = false
                        }
                    }
                }
            }
        }
    }

    div("mt-12") {
        ActionButton("metadata-update-request-button", "Update") {
            val response = try {
                MetadataService.populateNewMetadata(
                    tracks = tracks,
                    changeAlbum = getValue("Album"),
                    changeAlbumArt = getValue("Album Art"),
                    changeReleaseYear = getValue("Release Year"),
                    changeTrackNumber = getValue("Track Number"),
                )
            }  catch (e: Exception) {
                GGLog.logError("Failed to request new metadata for tracks!", e)
                Toast.error("Failed to get new metadata")

                return@ActionButton
            }

            val successes = response.successfulUpdates
            val failures = response.failedUpdates

            if (successes.isNotEmpty()) {
                val successMessage = if (successes.size == 1 && failures.isEmpty()) {
                    "'${Formatter.getPlayingTrackDisplayString(successes.first())}' was updated successfully"
                } else {
                    "${successes.size} track${if (successes.size == 1) " was" else "s were"} updated successfully"
                }

                if (failures.isNotEmpty()) {
                    val errorMessage = "\n\nHowever, the following songs failed to update:\n"

                    val failedTrackMessage = failures.joinToString("\n") { track ->
                        Formatter.getPlayingTrackDisplayString(track)
                    }

                    Toast.info(successMessage + errorMessage + failedTrackMessage)
                } else {
                    Toast.success(successMessage)
                }
            } else {
                if (failures.size > 1) {
                    Toast.error("Failed to find metadata for the selected tracks")
                } else {
                    Toast.error("Failed to find metadata for '${Formatter.getPlayingTrackDisplayString(failures.first())}'")
                }
            }

            Dialog.remove()
        }
    }

    mainScope.launch {
        Tooltip.registerAll(document.queryId("metadata-update-request-menu"))
    }
}
