package net.gorillagroove.localstorage

import net.gorillagroove.authentication.LoginResponse
import net.gorillagroove.api.UserId
import net.gorillagroove.util.GGLog.logInfo

// This really only exists to allow JS to store stuff as a cookie.
// The other objects can all have the same LocalStorage implementation whenever
// this issue is tackled: https://youtrack.jetbrains.com/issue/KT-20427
internal expect object CurrentUserStoreInternal {
    fun setInfo(response: LoginResponse)
    fun getInfo(): CurrentUserInfo?
    fun clearInfo()
}

internal data class CurrentUserInfo(
    val id: UserId,
    val email: String,
    val token: String,
)

internal object CurrentUserStore {
    private var cachedInfo: CurrentUserInfo? = null

    fun setInfo(response: LoginResponse) {
        CurrentUserStoreInternal.setInfo(response)
    }

    fun getInfo(): CurrentUserInfo? {
        return cachedInfo ?: run {
            CurrentUserStoreInternal.getInfo().also { cachedInfo = it }
        }
    }

    fun clearInfo() {
        cachedInfo = null
        CurrentUserStoreInternal.clearInfo()
    }
}

class NotSignedInException : RuntimeException()
