package net.gorillagroove.api

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import net.gorillagroove.hardware.RawDeviceType
import net.gorillagroove.review.ReviewSourceType
import net.gorillagroove.sync.strategies.TrackResponse
import net.gorillagroove.discovery.BackgroundTaskItemResponse
import net.gorillagroove.track.RemotePlayType


// I had to split these out b/c kotlinx.serialization gets pissy when I try to use the
// same EventType for both the request and the response, which I guess makes sense.
@Serializable
sealed class WebSocketResponse {
    abstract val messageType: EventType
}
// And I ended up removing the 'messageType' here because kotlinx.serialization doesn't
// seem to know how to use an existing field as a discriminator? So I have to omit the "messageType"
// field so that way when it serializes the entity, it can make a property called "messageType". Great.
// Good thing I didn't really have a need for this right now. Weird that it works for RESPONSES.
@Serializable
internal sealed class WebSocketRequest

@Serializable
@SerialName("NOW_PLAYING")
internal data class NowListeningRequest(
    val timePlayed: Double?,
    val trackId: TrackApiId?,
    val isShuffling: Boolean?,
    val isRepeating: Boolean?,
    val isPlaying: Boolean?,
    val volume: Double?,
    val muted: Boolean?,
    val isBetaWeb: Boolean?,
) : WebSocketRequest()

@Serializable
@SerialName("NOW_PLAYING")
data class NowListeningSocketResponse(
    override val messageType: EventType = EventType.NOW_PLAYING,
    val deviceId: DeviceId,
    val deviceName: String,
    val deviceType: RawDeviceType,
    val userId: UserId,
    val timePlayed: Double = 0.0,
    val trackData: PlayingTrackData?, // Won't be present for private tracks
    val isShuffling: Boolean = false,
    val isRepeating: Boolean = false,
    val isPlaying: Boolean = true,
    val volume: Double = 0.5,
    val muted: Boolean = false,
    val lastTimeUpdate: Long, // millis
    val isBetaWeb: Boolean = false
) : WebSocketResponse()

@Serializable
data class PlayingTrackData(
    val id: TrackApiId? = null,
    val name: String? = null,
    val artist: String? = null,
    val featuring: String? = null,
    val album: String? = null,
    val releaseYear: Int? = null,
    val albumArtLink: String? = null,
    val length: Int? = null,
    val inReview: Boolean? = null,
    val isPrivate: Boolean = false
)

@Serializable
@SerialName("CONNECTION_ESTABLISHED")
data class ConnectionEstablishedSocketResponse(
    override val messageType: EventType = EventType.CONNECTION_ESTABLISHED,
    val message: String,
) : WebSocketResponse()

data class ConnectionLostSocketResponse(
    override val messageType: EventType = EventType.CONNECTION_LOST,
) : WebSocketResponse()

data class NowListeningForceRefresh(
    override val messageType: EventType = EventType.NOW_PLAYING_FORCE_REFRESH,
) : WebSocketResponse()

@Serializable
@SerialName("REMOTE_PLAY")
internal data class RemotePlayRequest(
    val targetDeviceId: DeviceId,
    val trackIds: List<TrackApiId>?,
    val newFloatValue: Double?,
    val remotePlayAction: RemotePlayType
) : WebSocketRequest()

@Serializable
@SerialName("REMOTE_PLAY")
data class RemotePlaySocketResponse(
    override val messageType: EventType = EventType.REMOTE_PLAY,
    val tracks: List<TrackResponse>?,
    val newFloatValue: Double?,
    val remotePlayAction: RemotePlayType
) : WebSocketResponse()

@Serializable
@SerialName("REVIEW_QUEUE")
data class ReviewQueueSocketResponse(
    override val messageType: EventType = EventType.REVIEW_QUEUE,
    val sourceType: ReviewSourceType,
    val sourceDisplayName: String,
    val count: Int
) : WebSocketResponse()

@Serializable
@SerialName("BACKGROUND_TASK")
internal data class BackgroundTaskSocketResponse(
    override val messageType: EventType = EventType.BACKGROUND_TASK,
    val task: BackgroundTaskItemResponse,
    val trackId: TrackApiId?
) : WebSocketResponse()
