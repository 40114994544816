package net.gorillagroove.sync.strategies

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import net.gorillagroove.api.Api
import net.gorillagroove.api.UserSettingApiId
import net.gorillagroove.api.UserSettingId
import net.gorillagroove.db.*
import net.gorillagroove.db.Database.userSettingDao
import net.gorillagroove.sync.*
import net.gorillagroove.util.GGLog.logInfo
import net.gorillagroove.util.GGLog.logWarn
import net.gorillagroove.util.SettingType
import net.gorillagroove.util.Settings

object UserSettingSyncStrategy : BidirectionalStrategy {
    override val syncType: SyncableEntity = SyncableEntity.USER_SETTING

    override suspend fun syncDown(syncStatus: DbSyncStatus, onPageSyncedHandler: PageSyncHandler) {
        fetchSyncEntities<UserSettingResponse>(syncType, syncStatus, onPageSyncedHandler) { changeSet ->
            Database.db.transaction {
                // We can't rely on the "new" and "modified" groupings because a setting could be
                // created on-device first, then synced to the server. We need to treat every
                // setting like this is not the first time we've seen it.
                val existingKeys = userSettingDao.findAll().many().map { it.key }.toSet()
                val (modified, new) = changeSet.newAndModified.partition { existingKeys.contains(it.key) }

                // This is weirder than the other strategies because the database primary key is
                // not the same as the API ID. When we do the insert, we need the ID field omitted
                // so that way it is auto-incremented by the database system when added. But we can't
                // omit the ID when doing an update or else it'll change the primary key when the
                // unique 'key' property collides, and it is REPLACED during the upsert.
                // This may not make a lot of sense. I'm a bit brain-fried. But the unit test won't betray you.
                new.forEach {
                    val setting = it.asUserSetting()
                    userSettingDao.insert(setting)
                }

                val skippedKeys = mutableSetOf<String>()
                modified.forEach { response ->
                    val existingSetting = userSettingDao.findByKey(response.key).oneOrNull()
                    if (existingSetting?.synchronized == false) {
                        logWarn("Synced down a setting for key ${existingSetting.key} that was unsynchronized locally. Ignoring sync down.")
                        skippedKeys.add(existingSetting.key)
                    } else {
                        userSettingDao.updateByKey(
                            apiId = response.id,
                            value_ = response.value,
                            updatedAt = response.updatedAt,
                            synchronized = true,
                            key = response.key,
                        )
                    }
                }
                changeSet.removed.forEach {
                    val apiId = UserSettingApiId(it)
                    val existingSetting = userSettingDao.findByApiId(apiId).oneOrNull() ?: return@forEach

                    userSettingDao.deleteByApiId(apiId)

                    val type = SettingType.findByDbKey(existingSetting.key) ?: run {
                        logWarn("Unrecognized setting synced down: ${existingSetting.key}")
                        return@forEach
                    }
                    Settings.removeCacheValue(type)
                }

                changeSet.newAndModified.forEach { setting ->
                    val type = SettingType.findByDbKey(setting.key) ?: run {
                        logWarn("Unrecognized setting synced down: ${setting.key}")
                        return@forEach
                    }
                    if (!skippedKeys.contains(setting.key)) {
                        Settings.updateCacheValue(type, setting.value)
                    }
                }
            }
        }
    }

    override suspend fun syncUp() {
        val unsyncedSettings = userSettingDao.getAllUnsynchronized().many()
        if (unsyncedSettings.isNotEmpty()) {
            logInfo("Found ${unsyncedSettings.size} unsynced settings")
        }
        unsyncedSettings.forEach { dbSetting ->
            val request = UserSettingRequest(key = dbSetting.key, value = dbSetting.value_)
            val response: UserSettingResponse = Api.put("user-setting", request)

            userSettingDao.update(
                value_ = response.value,
                updatedAt = response.updatedAt,
                synchronized = true,
                id = dbSetting.id
            )
        }
    }
}

@Serializable
internal data class UserSettingRequest(
    val key: String,
    val value: String,
)

@Serializable
internal data class UserSettingResponse(
    val id: UserSettingApiId,
    val key: String,
    val value: String,
    val updatedAt: Instant,
) {
    fun asUserSetting() = DbUserSetting(
        // Some UserSettings are client-side-only, and thus would have ID collisions if we
        // tried to use the API's ID as our primary key. Thus, we store a separate variable for it.
        id = UserSettingId(0),
        apiId = id,
        key = key,
        value_ = value,
        synchronized = true,
        updatedAt = updatedAt,
    )
}
