@file:Suppress("FunctionName")

package components

import Dialog
import Toast
import hide
import kotlinx.browser.document
import kotlinx.coroutines.launch
import kotlinx.html.*
import kotlinx.html.dom.append
import kotlinx.html.dom.create
import kotlinx.html.js.div
import kotlinx.html.js.onClickFunction
import mainScope
import net.gorillagroove.api.YoutubeDownloadServerId
import net.gorillagroove.discovery.BonusFileDownloads
import net.gorillagroove.discovery.DownloadServer
import net.gorillagroove.discovery.DownloadServerService
import net.gorillagroove.util.GGLog
import net.gorillagroove.util.GGLog.logError
import onClickSuspend
import onSubmitSuspend
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import queryId
import show
import util.ByteUtil.download

private val header get() = document.queryId<HTMLElement>("download-server-header")
private val loadingSpinner get() = document.queryId<HTMLElement>("download-server-loader")

fun TagConsumer<*>.DownloadServerPage() = div("full-height") {
    id = "download-server"

    div {
        id = "download-server-header"
    }

    div {
        id = "download-server-table-wrapper"

        LoadingSpinner(id = "download-server-loader")

        table {
            thead {
                tr {
                    th {
                        span { +"Server Name" }
                    }
                    th {
                        span { +"Owner" }
                    }
                    th {
                        span { +"Status" }
                    }
                    th {
                        span { +"Active" }
                    }
                    th {
                        span { +"Versions" }
                    }
                    th {
                        span { +"Last Successful Ping" }
                    }
                    th {
                        span { +"Last Successful Download" }
                    }
                    th {
                        span { +"Last Attempted Download" }
                    }

                    th {
                        style = "width:70px"
                        span {
                            style = "width:70px"

                            i("fa-solid fa-arrows-rotate refresh") {
                                onClickSuspend = {
                                    DownloadServerPageManagement.renderServers()
                                }
                            }
                        }
                    }
                }
            }

            tbody {
                id = "download-server-table-body"
            }
        }
    }

    mainScope.launch {
        header.append(
            HeaderButton(
                iconClasses = "fa-brands fa-js",
                text = "Download Server JS",
                onClick = {
                    mainScope.launch launch2@ {
                        val response = try {
                            BonusFileDownloads.downloadYoutubeDownloadServerJavascript()
                        } catch (e: Throwable) {
                            Toast.error("Failed to download extension")
                            GGLog.logError("Failed to download FireFox extension!", e)
                            return@launch2
                        }

                        response.data.download(response.filename)
                    }
                }
            )
        )
        header.append(
            HeaderButton(
                iconClasses = "fa-solid fa-database",
                text = "Create Server",
                onClick = {
                    openModal()
                }
            )
        )
        DownloadServerPageManagement.renderServers()
    }
}

private object DownloadServerPageManagement {
    private var servers = emptyList<DownloadServer>()

    suspend fun renderServers() {
        loadingSpinner.show()

        servers = try {
            DownloadServerService.getServers()
        } catch (e: Exception) {
            Toast.error("Failed to load download servers")
            GGLog.logError("Failed to load download servers!", e)

            loadingSpinner.hide()

            return
        }

        val body = document.queryId<HTMLElement>("download-server-table-body")
        body.innerHTML = ""

        servers.forEach { server ->
            body.append {
                tr {
                    td { + server.name }
                    td { + server.ownerName }
                    td { + getServerStatus(server) }
                    td { + if (server.enabled) "Yep" else "Nope" }
                    td { + "${server.serverVersion} / ${server.downloaderVersion}" }
                    td { + server.lastSuccessfulPingString }
                    td { + server.lastSuccessfulDownloadString }
                    td { + server.lastDownloadAttemptString }
                    td(classes = "text-center") {
                        // A bit hacky. But the server address is a non-null property that is not returned if you do not
                        // have permission to edit the server, as it's a bit sensitive. So I am treating it existing as
                        // an indication that you can edit this server. Good enough. Probably.
                        if (server.serverAddress != null) {
                            span {
                                button(classes = "icon download-server-action-button") {
                                    tooltip = "Rename"

                                    onClickFunction = {
                                        openModal(server)
                                    }

                                    i("fa-solid fa-pen-to-square")
                                }

                                button(classes = "icon download-server-action-button") {
                                    tooltip = "View logs"

                                    onClickFunction = {
                                        Dialog.show(ServerLogModal(server.id))
                                    }

                                    i("fa-solid fa-file-lines")
                                }

                                button(classes = "icon download-server-action-button") {
                                    tooltip = "Delete"

                                    onClickFunction = {
                                        val dialog = GenericActionDialog(
                                            message = "Delete download server '${server.name}'?",
                                            acceptText = "Delete",
                                            rejectText = "Cancel",
                                            destructive = true,
                                            onAccept = {
                                                try {
                                                    DownloadServerService.deleteServer(server.id)
                                                } catch (e: Exception) {
                                                    GGLog.logError("Failed to delete server!", e)
                                                    Toast.error("Failed to delete server")

                                                    throw e
                                                }

                                                Toast.success("Server deleted successfully")
                                                renderServers()
                                            }
                                        )

                                        Dialog.show(dialog)
                                    }

                                    i("fa-solid fa-xmark")
                                }
                            }
                        }
                    }
                }
            }
        }

        Tooltip.registerAll(body)

        loadingSpinner.hide()
    }
}


private val serverName get() = document.queryId<HTMLInputElement>("server-name")
private val serverUrl get() = document.queryId<HTMLInputElement>("server-url")
private val serverAlerts get() = document.queryId<HTMLInputElement>("server-alerts")
private val serverActive get() = document.queryId<HTMLInputElement>("server-active")
private val serverStatus get() = document.queryId<HTMLElement>("server-status")
private val testDownloadStatus get() = document.queryId<HTMLElement>("test-download-status")

private fun AddDownloadServerDialog(server: DownloadServer?) = document.create.div {
    id = "download-server-dialog"

    form {
        onSubmitSuspend = onSubmit@ {
            try {
                if (serverName.value.isBlank()) {
                    Toast.info("Enter a server name")
                    return@onSubmit
                }
                if (serverUrl.value.isBlank()) {
                    Toast.info("Enter a server URL")
                    return@onSubmit
                }

                actionButtonChangeState("download-server-button", isUpdating = true)

                if (server == null) {
                    DownloadServerService.createServer(
                        name = serverName.value,
                        serverAddress = serverUrl.value,
                        receiveAlerts = serverAlerts.checked,
                        enabled = serverActive.checked,
                    )
                } else {
                    DownloadServerService.updateServer(
                        id = server.id,
                        name = serverName.value,
                        serverAddress = serverUrl.value,
                        receiveAlerts = serverAlerts.checked,
                        enabled = serverActive.checked,
                    )
                }

                DownloadServerPageManagement.renderServers()

                Dialog.removeAll()
            } catch (e: Exception) {
                logError("Failed to mutate download server", e)
                actionButtonChangeState("download-server-button", isUpdating = false)
            }
        }

        h4 {
            if (server == null) {
                +"Add Download Server"
            } else {
                +"Edit Download Server"
            }
        }

        small {
            +"This allows you to host a new node in the GorillaGroove distributed download swarm. "

            a(classes = "linkify") {
                + "Learn more"
                onClickFunction = {
                    Dialog.removeAll()
                    Dialog.show(HowToDialog())
                }
            }
        }

        div {
            id = "download-modal-content"

            div("entry space-between") {
                label {
                    htmlFor = "name"
                    +"Name"
                }
                input(InputType.text) {
                    this.id = "server-name"
                    name = "server-name"
                    placeholder = "Billy's House"
                    server?.let { value = server.name }
                }
            }
            div("entry space-between") {
                label {
                    htmlFor = "server-url"
                    +"Full URL"
                }
                input(InputType.text) {
                    this.id = "server-url"
                    name = "server-url"
                    placeholder = "http://55.55.55.55:8080"
                    server?.serverAddress?.let { value = it }
                }
            }
            div("entry space-between") {
                label {
                    htmlFor = "server-alerts"
                    +"Receive Alerts"

                    i("fa-solid fa-circle-info ml-6") {
                        tooltip = "You will receive an email when a ping fails to reach the server,\n" +
                                "or if five downloads fail consecutively (once per day at most)."
                        tooltipDelay = 100
                    }
                }
                span {
                    input(InputType.checkBox) {
                        this.id = "server-alerts"
                        name = "server-alerts"
                        checked = server?.receiveAlerts ?: false
                    }
                }
            }
            div("entry space-between") {
                label {
                    htmlFor = "server-active"
                    +"Active"
                }
                span {
                    input(InputType.checkBox) {
                        this.id = "server-active"
                        name = "server-active"
                        checked = server?.enabled ?: true
                    }
                }
            }
            div("entry space-between") {
                span {
                    ActionButton(
                        id = "test-ping-button",
                        text = "Test Ping",
                        classes = "slim",
                        type = ButtonType.button,
                        actionType = ActionButtonType.BENIGN
                    ) {
                        if (serverUrl.value.isEmpty()) {
                            Toast.info("Enter a valid URL")
                            return@ActionButton
                        }

                        // Ok this is a bit weird. But if we initiated a test ping of a server that already exists
                        // then we want to ping by the ID. This tells the backend to save the result of this ping
                        // to the database. Otherwise, we just ping a random URL and the result is thrown away.
                        // If we did ping by ID, then we want to reload the servers as well so that it's updated.
                        val success = if (server?.serverAddress == serverUrl.value) {
                            DownloadServerService.pingServer(server.id).also {
                                DownloadServerPageManagement.renderServers()
                            }
                        } else {
                            DownloadServerService.pingServer(serverUrl.value)
                        }

                        if (success) {
                            serverStatus.innerHTML = "Status: Successful"
                        } else {
                            serverStatus.innerHTML = "Status: Failed"
                        }
                    }
                }
                span("vertical-align") {
                    id = "server-status"

                    + "Status: Unchecked"
                }
            }
            if (server != null) {
                div("entry text-left") {
                    span {
                        ActionButton(
                            id = "download-server-version",
                            text = "Get Versions",
                            classes = "slim",
                            type = ButtonType.button,
                            actionType = ActionButtonType.BENIGN
                        ) {
                            try {
                                DownloadServerService.requestVersionInfo(server.id)
                                DownloadServerPageManagement.renderServers()
                                Toast.success("Successfully fetched latest version info")
                            } catch (e: Exception) {
                                Toast.error("Failed to get version info")
                            }
                        }
                    }
                }

                div("entry space-between") {
                    span {
                        ActionButton(
                            id = "test-download-button",
                            text = "Test Download",
                            classes = "slim",
                            type = ButtonType.button,
                            actionType = ActionButtonType.BENIGN
                        ) {
                            val success = try {
                                DownloadServerService.testDownload(server.id)
                                DownloadServerPageManagement.renderServers()
                                Toast.success("Successfully tested server download")
                                true
                            } catch (e: Exception) {
                                Toast.error("Failed to test server download")
                                false
                            }

                            if (success) {
                                testDownloadStatus.innerHTML = "Status: Successful"
                            } else {
                                testDownloadStatus.innerHTML = "Status: Failed"
                            }
                        }
                    }

                    span("vertical-align") {
                        id = "test-download-status"

                        + "Status: Unchecked"
                    }
                }
            }
        }

        hr {}

        div {
            ActionButton("download-server-button", "Save")
        }
    }
}

private fun getServerStatus(server: DownloadServer): String {
    return if (!server.enabled) {
        "⚪ Inactive"
    } else if (server.consecutivePingErrors > 0) {
        "\uD83D\uDD34 Ping failed"
    } else if (server.consecutiveDownloadErrors > 0) {
        val icon = if (server.consecutiveDownloadErrors < 4) {
            "\uD83D\uDFE1"
        } else {
            "\uD83D\uDD34"
        }
        "$icon Failed ${server.consecutiveDownloadErrors} download(s)"
    } else if (server.lastSuccessfulPing == null) {
        "⚪ Unchecked"
    } else {
        "\uD83D\uDFE2 Healthy"
    }
}

private fun openModal(server: DownloadServer? = null) {
    val modal = AddDownloadServerDialog(server)
    Dialog.show(modal)
    Tooltip.registerAll(modal)
}

private fun HowToDialog() = document.create.div("generic-dialog") {
    id = "how-to-dialog"

    h2 { + "How To Guide" }

    div("dialog-message text-left") {
        ol("how-to-section") {
            li {
                + "Click the 'Download Server JS' button on the 'Download Servers' page (the same one you are on right now)."
                + "Alternatively, you can wget / cURL to this URL as it does not require authentication: https://gorillagroove.net/api/file/download-server-code"
            }
            li {
                + "Take this JS and copy it to the computer that will be hosting the Download Server"
            }
            li {
                + "Download the appropriate version of yt-dlp for your architecture. There are different binaries for Windows, MacOS, and Linux, as well as 32 and 64 bit. You can do this "
                a("https://github.com/yt-dlp/yt-dlp/releases") {
                    target = "_blank"
                    + "from GitHub"
                }
                + ". If you are on Linux / MacOS, you may have to 'chmod +x' the binary"
            }
            li {
                + "Put the downloaded JS file and yt-dlp in the same directory somewhere and run it with the standard 'node download-server.js' command. (Alternatively you can configure the server to look for yt-dlp in a specific location. More on that later)"
            }
            li {
                + "The server should start up and tell you what its local IP address is. You will need to take this and set up port forwarding on your router."
            }
            li {
                + "Open back up the 'Create Server' dialog within GorillaGroove and input the IP address of your house (or whatever public IP you have)"
            }
        }

        div(classes = "text-left") {
            h3 { + "Configuration" }
            p {
                + "A config.json file may be created and placed within the same directory as download-server.js. It supports the following options"
            }
            ul("how-to-section") {
                li {
                    + "yt-dl-binary-location - The absolute path to the location of yt-dlp. When omitted, it uses the current directory of the node process"
                }
                li {
                    + "output-directory - The location where temporary files are written to during the download process. They should ideally be cleaned up, but may not if I have bugs. It defaults to the current directory of the node process"
                }
                li {
                    + "host - The local IP address that the Node server runs on. When omitted it should be the IP address of the host computer within the local network, e.g. 192.168.1.130"
                }
                li {
                    + "port - The port that the Node server runs on. Defaults to 8081"
                }
            }
        }

        div(classes = "text-left") {
            h3 { +"Command line arguments" }

            p { + "There are two command line arguments supported by this server. The first" }
            ul("how-to-section") {
                li {
                    +"--config <string> - Provide an absolute path to the location of the server config instead of having to have config.json in the same directory"
                }
                li {
                    +"--update - Have the download server download the latest version of the server JS file. After doing so, you will need to manually replace the JS yourself"
                }
            }
        }
    }

    div("space-around") {
        button(classes = "primary") {
            + "Got it"

            onClickFunction = {
                Dialog.remove()
            }
        }
    }
}

private val serverLogSpinner get() = document.queryId<HTMLElement>("download-server-log-loading-spinner")
private val serverLogTextArea get() = document.queryId<HTMLElement>("current-crash-report-log")

private fun ServerLogModal(serverId: YoutubeDownloadServerId) = document.create.div("p-relative flex-grow") {
    textArea {
        id = "current-crash-report-log"
        readonly = true
    }
    LoadingSpinner(id = "download-server-log-loading-spinner", classes = "full-center")

    mainScope.launch {
        try {
            serverLogSpinner.show()
            val logResponse = DownloadServerService.getLogs(serverId)
            serverLogTextArea.innerHTML = logResponse.logs
        } catch (e: Exception) {
            logError("Failed to retrieve server logs", e)
            serverLogTextArea.innerText = "Failed to retrieve server logs"
        }
        serverLogSpinner.hide()
    }
}
