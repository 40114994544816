package net.gorillagroove.track

import kotlinx.coroutines.Job
import net.gorillagroove.GGCommonInternal
import net.gorillagroove.api.*
import net.gorillagroove.api.NowListeningRequest
import net.gorillagroove.authentication.AuthService
import net.gorillagroove.hardware.DeviceType
import net.gorillagroove.hardware.PlatformDeviceUtil
import net.gorillagroove.sync.SyncCoordinator
import net.gorillagroove.util.CoroutineUtil.CancelledJob
import net.gorillagroove.util.GGLog.logDebug
import net.gorillagroove.track.NowPlayingEventType.*
import net.gorillagroove.user.UserService
import net.gorillagroove.util.*
import net.gorillagroove.util.GGLog.logError
import net.gorillagroove.util.Lock
import net.gorillagroove.util.SettingType
import net.gorillagroove.util.TimeUtil.now
import net.gorillagroove.util.Timer
import net.gorillagroove.util.use
import kotlin.time.Duration.Companion.seconds

object NowListeningService {
    private val lock: Lock = Lock()

    private val internalNowListeningData = mutableMapOf<UserId, MutableMap<DeviceId, NowListeningSocketResponse>>()
    val nowListeningData get() = lock.use { internalNowListeningData.toMap() }

    // Performs a keep-alive on the socket, basically
    private val playEventTimer = Timer(period = 30.seconds) {
        if (NowPlayingService.isPlaying) {
            val timeSinceLastTimeAdjustment = now() - NowPlayingService.lastTimeAdjustment
            if (timeSinceLastTimeAdjustment.inWholeSeconds > 20) {
                logError("The playback state appears to have become desynced. No time update recorded in $timeSinceLastTimeAdjustment. Pausing playback")
                NowPlayingService.pause()
            } else {
                attemptSendListenState()
            }
        }
    }

    internal fun registerForNowPlayingChanges() {
        NowPlayingService.registerEventHandler { event ->
            when (event.type) {
                PLAYBACK_STARTED, PLAYBACK_RESUMED, CURRENT_TRACK_CHANGED, ALL_TRACKS_CHANGED -> {
                    attemptSendListenState()
                    playEventTimer.start()
                }
                PLAYBACK_STOPPED, PLAYBACK_PAUSED -> {
                    playEventTimer.stop()

                    if (!ApiSocket.keepSocketAlive) {
                        ApiSocket.disconnect()
                    } else {
                        attemptSendListenState()
                    }
                }
                else -> {}
            }
        }
    }

    private fun attemptSendListenState() {
        if (OfflineModeService.offlineModeEnabled || GGCommonInternal.isIntegrationTesting || !AuthService.isAuthenticated()) {
            return
        }

        logDebug("Attempting to send listen data...")

        if (ApiSocket.isActive && ApiSocket.isConnected) {
            sendCurrentListenState()
        } else {
            ApiSocket.connect { success ->
                if (success) {
                    sendCurrentListenState()
                }
            }
        }
    }

    internal fun processNowListeningResponse(response: NowListeningSocketResponse) = lock.use {
        val userDevices = internalNowListeningData.getOrPut(response.userId) { mutableMapOf() }
        userDevices[response.deviceId] = response
    }

    internal fun sendCurrentListenState(): Job {
        if (!ApiSocket.isActive || !ApiSocket.isConnected) {
            logDebug("Did not send current listen state as socket is not active")
            return CancelledJob()
        }

        logDebug("About to send the listen state")
        val request = NowListeningRequest(
            timePlayed = NowPlayingService.currentTime,
            trackId = NowPlayingService.currentTrack?.apiId,
            isShuffling = NowPlayingService.isShuffling,
            isRepeating = NowPlayingService.isRepeating,
            isPlaying = NowPlayingService.isPlaying,
            volume = Settings.getDouble(SettingType.VOLUME, 1.0),
            muted = Settings.getBoolean(SettingType.MUTED, false),

            // This library is only in use on the beta web
            isBetaWeb = PlatformDeviceUtil.getDeviceType() == DeviceType.WEB,
        )
        return ApiSocket.sendMessage(request)
    }

    fun getListeningStateForUser(userId: UserId): NowListeningSocketResponse? {
        val state = nowListeningData[userId]
        return state?.filter { (_, data) -> data.isPlaying }
            // It is technically possible for a user to have multiple devices playing.
            // This is niche and weird enough that I'm not gonna bother dealing with it.
            ?.values
            ?.firstOrNull()
    }

    private fun clearOtherUserListeningState() = lock.use {
        val ownId = UserService.getCurrentUserId() ?: return@use
        val removedUsers = internalNowListeningData.keys - ownId
        removedUsers.forEach { internalNowListeningData.remove(it) }
    }

    var privateListeningEnabled: Boolean
        get() = Settings.getBoolean(SettingType.PRIVATE_LISTENING, false)
        set(value) {
            Settings.setBoolean(SettingType.PRIVATE_LISTENING, value)

            // Because this is a privacy-focused, server-side setting, sync immediately
            if (!GGCommonInternal.isIntegrationTesting) {
                SyncCoordinator.syncAsync()
            }

            if (value) {
                clearOtherUserListeningState()
                ApiSocket.broadcastNowPlayingRefresh()
            }
        }

    internal fun clear() {
        internalNowListeningData.clear()
    }
}
