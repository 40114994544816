package net.gorillagroove.user.permission

import com.squareup.sqldelight.ColumnAdapter
import kotlinx.serialization.Serializable
import net.gorillagroove.util.GGLog.logError
import kotlin.jvm.JvmInline

enum class UserPermissionType {
    WRITE_VERSION_HISTORY,
    EXPERIMENTAL,
    INVITE_USER,
    RUN_REVIEW_QUEUES,
    VIEW_CRASH_REPORTS,
    EDIT_YT_DL_SERVERS,
    ADMIN_YT_DL_SERVERS,
    VIEW_ALL_YEAR_END_SUMMARIES,
    UNKNOWN,

    ;

    fun toRawType() = RawUserPermissionType(this.name)
}

@Serializable
@JvmInline
value class RawUserPermissionType(val value: String) {
    fun asEnumeratedType(): UserPermissionType {
        return try {
            UserPermissionType.valueOf(value)
        } catch (e: Exception) {
            this.logError("Unknown enumerated type value '$value' for enum '${UserPermissionType::class.simpleName}'")
            UserPermissionType.UNKNOWN
        }
    }
}

object RawUserPermissionTypeAdapter : ColumnAdapter<RawUserPermissionType, String> {
    override fun decode(databaseValue: String) = RawUserPermissionType(databaseValue)
    override fun encode(value: RawUserPermissionType) = value.value
}
