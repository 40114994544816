package net.gorillagroove.discovery

import net.gorillagroove.api.Api
import net.gorillagroove.api.DownloadResponse

// IDK what to call this file. Leave me alone.
object BonusFileDownloads {
    suspend fun downloadFireFoxExtension(): DownloadResponse {
        return Api.download(Api.BASE_URL + "file/firefox-extension/1.0.1")
    }

    suspend fun downloadYoutubeDownloadServerJavascript(): DownloadResponse {
        return Api.download(Api.BASE_URL + "file/download-server-code")
    }
}
