@file:Suppress("FunctionName")

package components

import Dialog
import Toast
import kotlinx.browser.document
import kotlinx.coroutines.launch
import kotlinx.html.div
import kotlinx.html.dom.create
import kotlinx.html.h3
import kotlinx.html.js.div
import mainScope
import net.gorillagroove.review.ReviewQueueService
import net.gorillagroove.track.Track
import net.gorillagroove.user.UserService
import net.gorillagroove.util.GGLog
import net.gorillagroove.util.GGLog.logError

fun RecommendTracks(tracks: List<Track>) = document.create.div {
    val trackName = if (tracks.size == 1) "'${tracks.first().name}'" else "${tracks.size} tracks"
    val select = Multiselect(data = UserService.findOtherUsers(), "users") { it.name }

    h3 {
        + "Recommend Tracks"
    }

    div("space-between") {
        select.render(this, classes = "mr-12 mt-6")

        ActionButton("recommend-tracks-button", "Send", classes = "ml-12") {
            val users = select.getSelectedItems()
            if (users.isEmpty()) {
                return@ActionButton
            }

            val userName = if (users.size == 1) "'${users.first().name}'" else "${users.size} users"

            try {
                ReviewQueueService.recommendTracks(tracks.mapNotNull { it.apiId }, users.map { it.id })
            } catch (e: Exception) {
                Toast.error("Failed to recommend $trackName to $userName")
                GGLog.logError("Failed to recommend tracks!", e)

                return@ActionButton
            }

            Toast.success("Recommended $trackName to $userName")
            Dialog.remove()
        }
    }

    mainScope.launch {
        select.open()
    }
}
