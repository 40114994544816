package components

import Dialog
import LibraryViewMode
import PageRouter
import Toast
import hide
import invisible
import addEventListener
import isHidden
import kotlinx.browser.document
import kotlinx.coroutines.launch
import kotlinx.html.*
import kotlinx.html.dom.create
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onClickFunction
import kotlinx.html.js.onInputFunction
import mainScope
import net.gorillagroove.api.PlaylistId
import net.gorillagroove.discovery.ImportService
import net.gorillagroove.playlist.PlaylistService
import net.gorillagroove.review.ReviewQueueService
import net.gorillagroove.track.TrackSortable
import net.gorillagroove.user.permission.PermissionService
import net.gorillagroove.user.permission.UserPermissionType
import net.gorillagroove.util.GGLog
import net.gorillagroove.util.GGLog.logError
import net.gorillagroove.util.GGLog.logInfo
import net.gorillagroove.util.debounce
import org.khronos.webgl.ArrayBuffer
import org.w3c.dom.*
import org.w3c.dom.events.Event
import queryId
import removeChildren
import show
import toggleHidden
import util.ByteUtil.asByteArray
import visible
import kotlin.js.Promise
import kotlin.time.Duration.Companion.milliseconds

private val searchInput get() = document.getElementById("search-input") as HTMLInputElement
private val searchClearButton get() = document.getElementById("search-clear-button") as HTMLElement
private val libraryFilterMenuButton get() = document.getElementById("library-filter-menu-button") as HTMLButtonElement
private val trackFilterPopup get() = document.getElementById("track-filter-options") as HTMLElement?
private val playlistTrackExclusion get() = document.getElementById("playlist-track-exclusion-section") as HTMLElement
private val sectionHeaderButtons get() = document.getElementById("section-header-buttons") as HTMLElement


@Suppress("FunctionName")
fun TagConsumer<*>.LibraryHeader() = div {
    id = "library-header"

    div(classes = "space-between") {
        id = "library-header-top-row"

        div {
            div("input-group") {
                i("fa-solid fa-magnifying-glass")
                input {
                    id = "search-input"

                    placeholder = "Search"

                    onInputFunction = { event ->
                        LibraryHeader.searchTerm = (event.currentTarget!! as HTMLInputElement).value

                        if (LibraryHeader.searchTerm.isNotEmpty()) {
                            searchClearButton.show()
                        } else {
                            searchClearButton.hide()
                        }

                        debounce(this, 150.milliseconds) {
                            TrackTable.handleSearchTermChange(LibraryHeader.searchTerm, preserveExpansion = true)
                        }
                    }

                    value = LibraryHeader.searchTerm
                }

                val visibleClass = if (LibraryHeader.searchTerm.isNotBlank()) "" else "d-none"
                i("fa-solid fa-circle-xmark $visibleClass clickable") {
                    id = "search-clear-button"

                    onClickFunction = {
                        LibraryHeader.searchTerm = ""

                        searchInput.value = ""
                        searchClearButton.hide()
                        searchInput.focus()

                        TrackTable.handleSearchTermChange("", preserveExpansion = true)
                    }
                }
            }

            val filterDisplayedClass = if (viewMode.hidesLibraryViewMode) "invisible" else ""
            span("p-relative $filterDisplayedClass") {
                id = "library-filter-menu"

                button(classes = "icon") {
                    id = "library-filter-menu-button"

                    onClickFunction = { event ->
                        event.stopPropagation()
                        trackFilterPopup?.toggleHidden()
                    }

                    i("fa-solid fa-filter")
                }

                div("popup d-none") {
                    id = "track-filter-options"

                    onClickFunction = { it.stopPropagation() }

                    div {
                        input(InputType.checkBox) {
                            id = "show-hidden-tracks-checkbox"

                            onClickFunction = { event ->
                                LibraryHeader.showHiddenTracks = (event.currentTarget as HTMLInputElement).checked
                                TrackTable.fullRender()
                            }
                        }

                        label {
                            +"Show hidden tracks"

                            htmlFor = "show-hidden-tracks-checkbox"
                        }

                        i("fa-solid fa-circle-info") {
                            tooltip = "Show tracks that have been hidden from the library.\n" +
                                    "This is not the same as private tracks, which are only visible to the owner."
                            tooltipDelay = 100
                        }
                    }

                    val exclusionClass = if (PageRouter.currentViewMode == ViewMode.MY_LIBRARY) "" else "d-none"
                    div(exclusionClass) {
                        id = "playlist-track-exclusion-section"

                        label {
                            + "Exclude songs on playlist:"
                            select {
                                onChangeFunction = { event ->
                                    val select = event.currentTarget as HTMLSelectElement
                                    LibraryHeader.excludedPlaylist = if (select.value.isBlank()) {
                                        null
                                    } else {
                                        PlaylistId(select.value.toLong())
                                    }

                                    setTrackFilterActiveness()
                                    TrackTable.fullRender()
                                }

                                option {
                                    value = ""
                                    + ""
                                }

                                PlaylistService.findAll().forEach { playlist ->
                                    option {
                                        value = playlist.id.value.toInt().toString()
                                        +playlist.name
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        div("d-flex") {
            id = "section-header-buttons"
        }
    }

    div("d-flex") {
        id = "library-header-second-row"

        val hideClass = if (PageRouter.currentViewMode.hidesLibraryViewMode) "invisible" else ""

        div("d-flex $hideClass") {
            id = "second-row-hidden-container"

            + "View By:"

            ul {
                LibraryViewMode.entries.forEach { mode ->
                    li(classes = if (PageRouter.currentLibraryViewMode == mode) "active" else "") {
                        id = "library-display-mode-${mode.name}"

                        +mode.displayName

                        onClickFunction = {
                            PageRouter.setLibraryViewMode(mode)

                            document.querySelector("#library-header-second-row ul .active")?.classList?.remove("active")
                            document.getElementById("library-display-mode-${mode.name}")!!.classList.add("active")
                        }
                    }
                }
            }
        }

        div {
            id = "library-track-counter"
        }
    }

    mainScope.launch {
        LibraryHeader.updateFromViewTypeChange()
        Tooltip.registerAll(document.queryId("library-header"))
    }
}

@Suppress("FunctionName")
fun HeaderButton(
    iconClasses: String,
    text: String,
    htmlScope: (BUTTON.() -> Unit)? = null,
    onClick: (() -> Unit),
) = document.create.button(classes = "header-button") {
    onClickFunction = {
        GGLog.logInfo("Button '$text' clicked")
        onClick()
    }

    i(iconClasses)

    span {
        + text
    }

    htmlScope?.invoke(this)
}

private val viewMode get() = PageRouter.currentViewMode
private val libraryViewMode get() = PageRouter.currentLibraryViewMode

object LibraryHeader {
    var searchTerm: String = ""

    var showHiddenTracks: Boolean = false

    var excludedPlaylist: PlaylistId? = null

    init {
        document.body!!.addEventListener("click") {
            trackFilterPopup?.hide()
        }
    }

    fun updateFromViewTypeChange() {
        // Library view types
        val container = document.getElementById("second-row-hidden-container") as HTMLElement
        if (viewMode.hidesLibraryViewMode) {
            container.invisible()
        } else {
            container.visible()
        }

        // View modes
        val filterMenu = document.getElementById("library-filter-menu") as HTMLElement
        if (viewMode.hasFilterOptions) {
            filterMenu.visible()
        } else {
            filterMenu.invisible()
        }

        if (viewMode == ViewMode.MY_LIBRARY) {
            playlistTrackExclusion.show()
        } else {
            playlistTrackExclusion.hide()
        }

        adjustHiddenTrackValues()
        setTrackFilterActiveness()
        renderSectionHeaderButtons()
    }

    fun updateTrackTotals(tracks: List<TrackSortable>, selectedIndexes: Set<Int>) {
        val existingElement = document.queryId<HTMLElement>("library-track-counter")
        TrackCounter.update(existingElement, tracks, selectedIndexes)
    }
}

private fun adjustHiddenTrackValues() {
    LibraryHeader.showHiddenTracks = !(viewMode == ViewMode.MY_LIBRARY && libraryViewMode == LibraryViewMode.TRACK)
    val hiddenTrackCheckbox = document.getElementById("show-hidden-tracks-checkbox") as HTMLInputElement
    hiddenTrackCheckbox.checked = LibraryHeader.showHiddenTracks
}

private fun setTrackFilterActiveness() {
    if (playlistTrackExclusion.isHidden()) {
        libraryFilterMenuButton.classList.remove("active")
    } else {
        if (LibraryHeader.excludedPlaylist == null) {
            libraryFilterMenuButton.classList.remove("active")
        } else {
            libraryFilterMenuButton.classList.add("active")
        }
    }
}

private fun renderSectionHeaderButtons() {
    sectionHeaderButtons.removeChildren()

    when (viewMode) {
        ViewMode.MY_LIBRARY -> {
            sectionHeaderButtons.append(
                HeaderButton(
                    iconClasses = "fa-solid fa-cloud-arrow-up",
                    text = "Upload Songs",
                    htmlScope = {
                        input(InputType.file, classes = "d-none") {
                            id = "file-upload"
                            accept = "audio/*"
                            onChangeFunction = ::handleFileUploadClick
                            // Stop propagation, else the button being clicked will click this input, which propagates back to the button...
                            onClickFunction = { it.stopPropagation() }
                            multiple = true
                        }
                    },
                    onClick = {
                        (document.getElementById("file-upload") as HTMLInputElement).click()
                    }),
                HeaderButton(iconClasses = "fa-brands fa-youtube", text = "YouTube Download") {
                    Dialog.show(YouTubeDownload())
                },
            )
        }
        ViewMode.REVIEW_QUEUE -> {
            sectionHeaderButtons.append(
                HeaderButton(iconClasses = "fa-solid fa-pen-to-square", text = "Review Sources") {
                    Dialog.show(ReviewSourceEdit())
                },
            )

            if (PermissionService.hasPermission(UserPermissionType.RUN_REVIEW_QUEUES)) {
                sectionHeaderButtons.append(
                    HeaderButton(iconClasses = "fa-solid fa-database", text = "Run Review Queues") {
                        mainScope.launch {
                            try {
                                Toast.info("Queues started running")

                                ReviewQueueService.runReviewQueues()
                                Toast.success("Review queues finished running")
                            } catch (e: Exception) {
                                Toast.error("Failed to run review queues")
                            }
                        }
                    },
                )
            }
        }
        else -> {}
    }
}

private fun handleFileUploadClick(e: Event) {
    val input = e.currentTarget as HTMLInputElement

    mainScope.launch {
        val files = input.files!!.asList()
        val filenameToFile = files.associateBy { it.name }

        try {
            ImportService.uploadTracks(files.map { it.name }) { filename ->
                val fileHandle = filenameToFile.getValue(filename)
                return@uploadTracks fileHandle.asByteArray()
            }
        } catch (e: Exception) {
            GGLog.logError("Upload of files: '$files' failed!", e)
        }
    }
}

external class Blob : org.w3c.files.Blob {
    fun arrayBuffer(): Promise<ArrayBuffer>
}
