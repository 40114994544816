@file:Suppress("FunctionName")

package components

import Dialog
import Toast
import hide
import kotlinx.browser.document
import kotlinx.coroutines.launch
import kotlinx.html.*
import kotlinx.html.dom.append
import kotlinx.html.dom.create
import kotlinx.html.js.onClickFunction
import mainScope
import net.gorillagroove.api.DeviceId
import net.gorillagroove.user.Device
import net.gorillagroove.user.DeviceService
import net.gorillagroove.util.Formatter
import net.gorillagroove.util.GGLog
import net.gorillagroove.util.GGLog.logError
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLSelectElement
import queryId
import show
import kotlin.text.Typography.nbsp

private val loadingSpinner get() = document.queryId<HTMLElement>("device-loader")

fun TagConsumer<*>.DeviceManagementPage() = div("full-height") {
    id = "device-management"

    div {
        id = "device-table-wrapper"

        LoadingSpinner(id = "device-loader")

        table {
            thead {
                tr {
                    th {
                        span { +"Name" }
                    }
                    th {
                        span { +"Type" }
                    }
                    th {
                        span { +"Version" }
                    }
                    th {
                        span { +"Last IP" }
                    }
                    th {
                        span { +"Last listened" }
                    }

                    th {
                        // Sorry for using an inline style, Audrey.
                        style = "width:70px"
                        span {
                            style = "width:70px"

                            + "$nbsp"
                        }
                    }
                }
            }

            tbody {
                id = "device-management-table-body"
            }
        }
    }

    mainScope.launch {
        DeviceManagement.renderDevices()
    }
}

private object DeviceManagement {
    private var devices = emptyList<Device>()

    suspend fun renderDevices() {
        loadingSpinner.show()

        devices = try {
            DeviceService.findAll()
        } catch (e: Exception) {
            Toast.error("Failed to load devices")
            GGLog.logError("Failed to load devices!", e)

            loadingSpinner.hide()

            return
        }

        val body = document.queryId<HTMLElement>("device-management-table-body")
        body.innerHTML = ""

        val currentId = DeviceService.getCurrentDeviceId()

        devices.forEach { device ->
            body.append {
                val isCurrentDevice = device.deviceId == currentId
                tr {
                    td(classes = if (isCurrentDevice) "bold" else "") {
                       + (device.deviceName + if (isCurrentDevice) " (Current device)" else "")
                    }
                    td { + device.deviceType.displayName }
                    td { + device.applicationVersion }
                    td { + device.lastIp }
                    td { + Formatter.formatToUserDateFormat(device.lastListenedOn) }
                    td(classes = "text-center") {
                        span {
                            button(classes = "icon device-action-button") {
                                tooltip = "Rename"

                                onClickFunction = { rename(device) }

                                i("fa-solid fa-pen-to-square")
                            }
                            button(classes = "icon device-action-button") {
                                tooltip = "Archive"

                                onClickFunction = { archive(device) }

                                i("fa-solid fa-box-archive")
                            }
                            button(classes = "icon device-action-button") {
                                tooltip = "Merge"

                                onClickFunction = { merge(device) }

                                i("fa-solid fa-minimize")
                            }
                        }
                    }
                }
            }
        }

        Tooltip.registerAll(body)

        loadingSpinner.hide()
    }

    private fun rename(device: Device) {
        Dialog.show(
            InputActionDialog(
                message = "Rename device",
                defaultValue = device.deviceName,
                buttonClasses = "primary",
                acceptText = "Update",
                onAccept = { newName ->
                    try {
                        DeviceService.renameDevice(device.id, newName)
                    } catch (e: Exception) {
                        GGLog.logError("Failed to update device name!", e)
                        Toast.error("Failed to update device name")

                        throw e
                    }

                    Toast.success("Device rename successful")
                    renderDevices()
                }
            )
        )
    }

    private fun archive(device: Device) {
        val dialog = GenericActionDialog(
            message = "Archiving a device will prevent it from showing up on this screen.\n\n" +
                    "Use it to hide devices that you want to keep the history from, but are no longer relevant.",
            acceptText = "Make it so",
            rejectText = "Cancel",
            onAccept = {
                try {
                    DeviceService.archiveDevice(device.id)
                } catch (e: Exception) {
                    GGLog.logError("Failed to archive device!", e)
                    Toast.error("Failed to archive device")

                    throw e
                }

                Toast.success("Device archival successful")
                renderDevices()
            }
        )

        dialog.classList.add("text-left")

        Dialog.show(dialog)
    }

    private fun merge(device: Device) {
        val dialog = document.create.div {
            div("pre-line text-left") {
                + """
                    Merging this device into another device will effectively delete it.
					All prior and future song listens will use the selected device.
					This action is not reversible.

					Use this to link multiple browsers on the same computer,
					or to keep your phone history on the same device after a factory reset.
                """.trimIndent()
            }

            hr {  }

            val otherDevices = devices.filter { it.id != device.id && it.deviceType == device.deviceType }
            div {
                + "Merge ${device.deviceName} into: "

                div {
                    select {
                        id = "merge-devices-select"

                        otherDevices.forEach { targetDevice ->
                            option {
                                value = targetDevice.id.value.toString()
                                + targetDevice.deviceName
                            }
                        }
                    }
                }
            }

            div("mt-12") {
                val actionId = "merge-device-button"

                ActionButton(id = actionId, text = "FU-SHUN HA!") {
                    actionButtonChangeState(actionId, isUpdating = true)

                    try {
                        val targetDeviceIdStr = document.queryId<HTMLSelectElement>("merge-devices-select")
                            .value
                            .takeIf { it.isNotBlank() }
                            ?: return@ActionButton

                        val targetDeviceId = DeviceId(targetDeviceIdStr.toLong())

                        DeviceService.mergeDevices(id = device.id, targetId = targetDeviceId)
                    } catch (e: Exception) {
                        GGLog.logError("Failed to merge devices!", e)
                        Toast.error("Failed to merge devices")

                        actionButtonChangeState(actionId, isUpdating = false)
                        return@ActionButton
                    }

                    Toast.success("Device merge successful")
                    renderDevices()

                    Dialog.remove()
                }
            }
        }

        Dialog.show(dialog)
    }
}
