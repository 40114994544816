package net.gorillagroove.playlist

import kotlinx.serialization.Serializable
import net.gorillagroove.api.*
import net.gorillagroove.db.*
import net.gorillagroove.db.Database.playlistDao
import net.gorillagroove.db.Database.playlistTrackDao
import net.gorillagroove.db.Database.playlistUserDao
import net.gorillagroove.db.Database.trackDao
import net.gorillagroove.sync.strategies.PlaylistResponse
import net.gorillagroove.sync.strategies.PlaylistTrackResponse
import net.gorillagroove.sync.strategies.PlaylistUserResponse
import net.gorillagroove.track.*
import net.gorillagroove.track.toTracks
import net.gorillagroove.user.UserService
import net.gorillagroove.util.GGLog.logError
import net.gorillagroove.util.GGLog.logInfo
import net.gorillagroove.util.GGLog.logWarn
import net.gorillagroove.util.reversed

object PlaylistService {
    fun getTracksForPlaylist(
        playlistId: PlaylistId,
        genericFilter: String? = null,
        sort: List<Pair<TrackColumn, SortDirection>> = listOf(TrackColumn.SORT_IDENTIFIER to SortDirection.ASC),
    ): List<LoadedPlaylistTrack> {
        val playlistTracks = playlistTrackDao.getTracksForPlaylist(
            playlistId = playlistId,
            genericFilter = genericFilter
        ).many()

        val tracks = trackDao.findByIds(playlistTracks.map { it.trackId }).many().toTracks().associateBy { it.id }

        val loadedTracks = playlistTracks.map { LoadedPlaylistTrack(it.toPlaylistTrack(), tracks.getValue(it.trackId)) }

        val sortBySortOrder = listOf(TrackColumn.SORT_IDENTIFIER to SortDirection.ASC)

        // Why do we do this at all? It is because the sortOrder property can have holes in it. If we have a playlist with
        // tracks "0, 1, 2, 3" and then we delete track 2, then the sortOrder goes "0, 1, 3" which looks weird in the UI.
        // This exists to compact the holes down for display purposes.
        // Why not compact it on the backend when something is deleted? It just sounds annoying and it will result in more data syncs.
        // Deleting a single track off of a playlist will result in re-syncing every other track, and while out of sync, the
        // hole will still exist. This feels pretty firmly like an appropriate client-side solution to me.
        val sortOrderTracks = TrackSort.sortTracks(loadedTracks, sortBySortOrder)
        sortOrderTracks.forEachIndexed { index, loadedPlaylistTrack -> loadedPlaylistTrack.order = index }

        // If we are sorting based off the playlist's natural sort order, then there is no additional work to do.
        // Return early and do not do an unnecessary secondary sort.
        if (sort == sortBySortOrder) {
            return loadedTracks
        }

        // Otherwise, sort the tracks as the user intended. It's double sorting but that's the price of no weird UI.
        // Most of the time, a playlist is sorted by the playlist's sort order anyway, probably. It is the default for a reason.
        // Also, playlists are GENERALLY not MASSIVE. So sorting twice is probably rarely happening on a large N.
        return TrackSort.sortTracks(loadedTracks, sort)
    }

    internal fun findDbPlaylistById(id: PlaylistId): DbPlaylist? = playlistDao.findById(id).oneOrNull()
    fun findTrackById(id: PlaylistTrackId): PlaylistTrack? = playlistTrackDao.findById(id).oneOrNull()?.toPlaylistTrack()

    fun findById(id: PlaylistId): Playlist? {
        val dbPlaylist = playlistDao.findById(id).oneOrNull() ?: return null
        val users = getUsersRecordsOnPlaylist(dbPlaylist.id)

        return dbPlaylist.toPlaylist(
            permission = users.findOwnPermission(),
            userCount = users.size
        )
    }

    fun findAll(): List<Playlist> {
        val playlistUsers = playlistUserDao.findAll().many().groupBy { it.playlistId }
        val playlists = playlistDao.findAll().many()

        val userId = UserService.getCurrentUserId()

        return playlists.map { dbPlaylist ->
            val usersOnPlaylist = playlistUsers[dbPlaylist.id] ?: run {
                logWarn("No users found on playlist while fetching all playlists")
                emptyList()
            }

            return@map dbPlaylist.toPlaylist(usersOnPlaylist.findOwnPermission(userId), usersOnPlaylist.size)
        }
        .sortedWith(compareBy(String.CASE_INSENSITIVE_ORDER) { it.name })
    }

    suspend fun createPlaylist(name: String): Playlist {
        logInfo("Creating playlist with name $name")

        val response = try {
            Api.post<PlaylistWithUserResponse>("playlist/v2", PlaylistMutateRequest(name))
        } catch (e: Exception) {
            logError("Failed to create playlist!", e)
            throw e
        }

        val dbPlaylist = response.playlist.asDbPlaylist()
        playlistDao.upsert(dbPlaylist)

        val dbPlaylistUser = response.playlistUser.asPlaylistUser()
        playlistUserDao.upsert(dbPlaylistUser)

        Database.forceSave()

        return dbPlaylist.toPlaylist(permission = PlaylistOwnershipType.OWNER, userCount = 1)
    }

    suspend fun updatePlaylist(playlistId: PlaylistId, name: String): Playlist {
        logInfo("Updating playlist ${playlistId.value} to have name $name")

        val response = try {
            Api.put<PlaylistResponse>("playlist/${playlistId.value}", PlaylistMutateRequest(name))
        } catch (e: Exception) {
            logError("Failed to update playlist!", e)
            throw e
        }

        val dbPlaylist = response.asDbPlaylist()
        playlistDao.upsert(dbPlaylist)

        val users = getUsersRecordsOnPlaylist(playlistId)

        Database.forceSave()

        return dbPlaylist.toPlaylist(users.findOwnPermission(), users.size)
    }

    suspend fun deletePlaylist(playlistId: PlaylistId) {
        logInfo("Deleting playlist ${playlistId.value}")

        try {
            Api.delete<Unit>("playlist/${playlistId.value}")
        } catch (e: Exception) {
            logError("Failed to delete playlist!", e)
            throw e
        }

        deletePlaylistInternal(playlistId)

        Database.forceSave()
    }

    internal fun deletePlaylistInternal(playlistId: PlaylistId) {
        // We need to delete everything from PlaylistUser and PlaylistTrack that depend on this playlist first,
        // or else we will violate FK constraints in the database
        playlistUserDao.deleteFromPlaylist(playlistId)
        playlistTrackDao.deleteFromPlaylist(playlistId)
        playlistDao.delete(playlistId)

        // If we deleted a shared playlist, we may have Tracks saved that do not belong to us that need to be cleaned up.
        trackDao.deleteOrphanedTracks(UserService.requireCurrentUserId())
    }

    // I am asking to be provided TrackId and not TrackApiId in case I eventually allow you
    // to have locally-stored music on playlists. Pipe dream, maybe. But who doesn't like dreaming of pipes?
    suspend fun addTracks(trackIds: List<TrackId>, playlistIds: List<PlaylistId>): List<PlaylistTrack> {
        val trackLocalIdsToApiIds = TrackService.findApiIdForLocalId(trackIds)

        val request = AddToPlaylistRequest(
            trackIds = trackIds.mapNotNull { trackLocalIdsToApiIds[it]?.value },
            playlistIds = playlistIds,
        )
        logInfo("Adding tracks (${request.trackIds.map { it }}) to playlists (${request.playlistIds.map { it.value }})")

        val response = try {
            Api.post<AddToPlaylistResponse>("playlist/track", request)
        } catch (e: Exception) {
            logError("Failed to add PlaylistTracks!", e)
            throw e
        }

        val trackApiIdsToLocalIds = trackLocalIdsToApiIds
            .filterValues { it != null }
            // IDK why filtering out nulls doesn't allow Kotlin to smart cast to a non-null type.
            // I don't think this should be necessary.
            .mapValues { it.value!! }
            .reversed()

        val playlistTracks = response.items.map { playlistTrackResponse ->
            playlistTrackResponse.asPlaylistTrack(trackApiIdsToLocalIds)
        }
        playlistTracks.forEach { playlistTrackDao.upsert(it) }

        Database.forceSave()

        return playlistTracks.toPlaylistTracks()
    }

    suspend fun removeTracks(playlistTrackIds: List<PlaylistTrackId>) {
        logInfo("Removing PlaylistTracks: ${playlistTrackIds.map { it.value }}")

        val params = mapOf("playlistTrackIds" to playlistTrackIds.map { it.value })
        try {
            Api.delete<Unit>("playlist/track", params)
        } catch (e: Exception) {
            logError("Failed to add delete PlaylistTracks!", e)
            throw e
        }

        playlistTrackIds.forEach { playlistTrackDao.delete(it) }

        Database.forceSave()
    }

    suspend fun reorderPlaylist(playlistId: PlaylistId, playlistTrackIds: List<PlaylistTrackId>) {
        logInfo("Reordering tracks on Playlist: ${playlistId.value}. New order: ${playlistTrackIds.map { it.value }}")

        val uniqueIds = playlistTrackIds.toSet()
        if (playlistTrackIds.size != uniqueIds.size) {
            val nonUniqueIds = playlistTrackIds
                .groupBy { it.value }
                .filter { it.value.size > 1 }
                .keys

            logError("Non-unique PlaylistTrackIds provided to Playlist reorder request! This is nonsensical. Offending ids: $nonUniqueIds")
            throw IllegalArgumentException("Playlist reorder must not contain duplicate IDs: $nonUniqueIds")
        }

        val request = ReorderPlaylistRequest(playlistId, playlistTrackIds)

        try {
            Api.put<Unit>("playlist/track/sort-order", request)
        } catch (e: Exception) {
            logError("Failed to add delete PlaylistTracks!", e)
            throw e
        }

        val idToPlaylistTrack = playlistTrackDao.findByIds(playlistTrackIds).many().associateBy { it.id }

        val playlistTracks = playlistTrackIds.map { idToPlaylistTrack.getValue(it) }
        val updatedPlaylistTracks = playlistTracks.mapIndexed { index, playlistTrack ->
            playlistTrack.copy(sortOrder = index)
        }

        Database.db.transaction {
            updatedPlaylistTracks.forEach { playlistTrackDao.upsert(it) }
        }

        Database.forceSave()

        logInfo("Playlist tracks were reordered")
    }

    suspend fun editUserPermission(
        userIds: List<UserId>,
        playlistId: PlaylistId,
        permission: PlaylistOwnershipType
    ): List<PlaylistUser> {
        return editUserPermission(
            playlistId,
            userIds.map { userId ->
                UserWithPlaylistOwnership(userId, permission)
            }
        )
    }

    suspend fun editUserPermission(
        playlistId: PlaylistId,
        userWithPermission: List<UserWithPlaylistOwnership>,
    ): List<PlaylistUser> {
        val userIds = userWithPermission.map { it.user }.toSet()

        // Only playlist owners can call into this function, and you can't change your own permission.
        // If you did, you'd be leaving the playlist without an owner.
        // I probably should actually check if you are the owner and throw an exception if you aren't...
        // but whatever. The API will throw an error. It'll just take a bit longer.
        val ownUserId = UserService.requireCurrentUserId()
        if (userIds.contains(ownUserId)) {
            throw IllegalArgumentException("You may not update your own permission!")
        }

        val ownPlaylistUser = findPlaylistUserRecord(ownUserId, playlistId)!!
        if (ownPlaylistUser.ownershipType.asEnumeratedType() != PlaylistOwnershipType.OWNER) {
            throw IllegalArgumentException("Only a playlist owner may edit permissions!")
        }

        val requestItems = userWithPermission.map { UserWithPlaylistOwnershipSerializable(it.user.value, it.permission) }
        val request = PlaylistUserUpdateRequest(requestItems)

        val response = try {
            Api.put<PlaylistUserMultiResponse>("playlist/${playlistId.value}/user-permission", request)
        } catch (e: Exception) {
            logError("Failed to update permission for users ${userIds.map { it.value }} on playlist: ${playlistId.value}!", e)
            throw e
        }

        val savedPlaylistUsers = response.items.map { responseUser ->
            if (responseUser.deleted) {
                playlistUserDao.delete(responseUser.id)
                return@map responseUser.asPlaylistUser()
            }

            val newPlaylistUser = responseUser.asPlaylistUser()
            playlistUserDao.upsert(newPlaylistUser)

            // This is here because a playlist can have only one owner. So if we promote another
            // user to be the owner, then it means that we become a "writer". We will sync the change
            // down after, but we may as well update it to be the correct thing right away.
            if (responseUser.ownershipType.asEnumeratedType() == PlaylistOwnershipType.OWNER) {
                val updatedPlaylistUser = ownPlaylistUser.copy(ownershipType = PlaylistOwnershipType.WRITER.toRawType())
                playlistUserDao.upsert(updatedPlaylistUser)
            }

            newPlaylistUser
        }

        Database.forceSave()

        return savedPlaylistUsers.toPlaylistUsers()
    }

    fun getUsersOnPlaylist(playlistId: PlaylistId): List<PlaylistUser> {
        return getUsersRecordsOnPlaylist(playlistId).toPlaylistUsers()
    }

    private fun getUsersRecordsOnPlaylist(playlistId: PlaylistId): List<DbPlaylistUser> {
        val playlistUsers = playlistUserDao.findUsersOnPlaylist(playlistId).many()
        val users = UserService.findByIds(playlistUsers.map { it.userId }).associateBy { it.id }

        return playlistUsers.sortedWith(
            compareByDescending<DbPlaylistUser> { it.ownershipType.asEnumeratedType().permissionLevel }
                .thenBy(String.CASE_INSENSITIVE_ORDER) { users.getValue(it.userId).name }
        )
    }

    fun findPlaylistUser(userId: UserId, playlistId: PlaylistId): PlaylistUser? {
        return findPlaylistUserRecord(userId, playlistId)?.toPlaylistUser()
    }

    private fun findPlaylistUserRecord(userId: UserId, playlistId: PlaylistId): DbPlaylistUser? {
        return playlistUserDao.findByUserAndPlaylist(userId, playlistId).oneOrNull()
    }

    internal suspend fun getUsersOnPlaylistFromApi(playlistId: PlaylistId): List<DbPlaylistUser> {
        val response = try {
            Api.get<PlaylistUserMultiResponse>("playlist/${playlistId.value}/user/")
        } catch (e: Exception) {
            logError("Failed to get users on playlist: ${playlistId.value}!", e)
            throw e
        }

        return response.items.map { it.asPlaylistUser() }
    }
}

@Serializable
internal data class PlaylistMutateRequest(val name: String)

@Serializable
internal data class AddToPlaylistRequest(
    // FIXME For some reason I am getting an issue in KotlinJS where it does not serialize the TrackApiIds in
    //  this specific request as Longs and instead tries to send them as "trackIds": [ TrackApiId(value=416) ],
    //  which obviously doesn't work. This does not reproduce in unit tests. It's only in the real-world
    //  frontend implementation that I am seeing it break. I don't want to deal with it, so I made these Longs.
    val trackIds: List<Long>,
    val playlistIds: List<PlaylistId>,
)

@Serializable
data class AddToPlaylistResponse(val items: List<PlaylistTrackResponse>)

@Serializable
internal data class ReorderPlaylistRequest(
    val playlistId: PlaylistId,
    val playlistTrackIds: List<PlaylistTrackId>,
)

data class LoadedPlaylistTrack(val playlistTrack: PlaylistTrack, val track: Track) : TrackSortable {
    // This is for displaying the order on the frontend without gaps, as sortOrder is not compacted on the API when a track is deleted.
    // I COULD do that, but that sounds pretty messy tbh. I'd rather just solve this on the client since it's just for display purposes.
    // Having "holes" in the sortOrder is not an issue for any other reason than it looks weird.
    var order: Int = 0
        internal set
}

@Serializable
internal data class PlaylistUserMultiResponse(val items: List<PlaylistUserResponse>)

private fun Collection<DbPlaylistUser>.findOwnPermission(
    userId: UserId? = UserService.getCurrentUserId()
): PlaylistOwnershipType {
    if (userId == null) {
        return PlaylistOwnershipType.NONE
    }

    return this.find { it.userId == userId }?.ownershipType?.asEnumeratedType() ?: PlaylistOwnershipType.NONE
}

@Serializable
data class PlaylistWithUserResponse(
    val playlist: PlaylistResponse,
    val playlistUser: PlaylistUserResponse,
)

data class UserWithPlaylistOwnership(val user: UserId, val permission: PlaylistOwnershipType)

@Serializable
internal class UserWithPlaylistOwnershipSerializable(val userId: Long, val permission: PlaylistOwnershipType)

@Serializable
internal data class PlaylistUserUpdateRequest(val users: List<UserWithPlaylistOwnershipSerializable>)

