@file:Suppress("FunctionName")

package components

import Dialog
import Toast
import kotlinx.browser.document
import kotlinx.coroutines.launch
import kotlinx.html.*
import kotlinx.html.dom.create
import kotlinx.html.js.div
import kotlinx.html.js.onInputFunction
import mainScope
import net.gorillagroove.track.Track
import net.gorillagroove.track.TrackService
import net.gorillagroove.util.GGLog
import net.gorillagroove.util.GGLog.logError
import net.gorillagroove.util.round
import onSubmitSuspend
import org.w3c.dom.HTMLInputElement
import queryId
import kotlin.math.ln
import kotlin.math.pow

private val volumeSlider: HTMLInputElement get() = document.queryId("adjust-volume-slider")
private val volumeTextInput: HTMLInputElement get() = document.queryId("adjust-volume-text")

fun TrackVolumeModal(tracks: List<Track>) = document.create.div {
    id = "adjust-volume-modal"

    h3 {
        + "Adjust Volume"
    }

    val pluralWords1 = if (tracks.size == 1) "this track's" else "these tracks'"
    val pluralWords2 = if (tracks.size == 1) "its" else "their"
    div("small-text") { + "Adjust $pluralWords1 volume as a percentage of $pluralWords2 existing volume" }

    form {
        onSubmitSuspend = onSubmit@ {
            if (isActionButtonUpdating("adjust-volume-button")) {
                return@onSubmit
            }

            val volume = volumeTextInput.value.toDouble()

            // FIXME currently allowing adjustments of 1.0 to be made to fix a previous bug where MP3 files were not
            //  properly getting their volumes adjusted. So "adjusting" volume with 1.0 is just resetting the MP3s.
//            if (volume == 1.0) {
//                Toast.info("No volume adjustment made")
//            } else {
                actionButtonChangeState("adjust-volume-button", isUpdating = true)

                try {
                    TrackService.adjustVolume(tracks, volume)

                    Toast.success("Volume adjusted successfully")

                    Dialog.remove()
                } catch (e: Exception) {
                    GGLog.logError("Failed to adjust track volume!", e)
                    Toast.error("Failed to adjust volume")

                    actionButtonChangeState("adjust-volume-button", isUpdating = false)
                }
//            }
        }

        div("space-between mt-12") {
            span { + "½" }
            span { + "1" }
            span { + "2" }
        }

        div("mt-4") {
            input(InputType.range) {
                id = "adjust-volume-slider"

                min = "0.25"
                max = "0.75"
                value = "0.5"
                step = "0.004"

                onInputFunction = { event ->
                    val rawVolume = (event.currentTarget as HTMLInputElement).value.toDouble()

                    // This is the math to convert between them, based off of a best fit line that converts the following:
                    // 0 -> 0.25
                    // 0.25 -> 0.5
                    // 0.5 -> 1
                    // 0.75 -> 2
                    // 1 -> 4
                    val newPercentage = 0.25 * 16.0.pow(rawVolume)
                    volumeTextInput.value = newPercentage.round(2)
                }
            }
        }

        div("mt-6") {
            input(InputType.number) {
                id = "adjust-volume-text"

                step = "0.01"
                value = "1.0"

                onInputFunction = { event ->
                    val realPercent = (event.currentTarget as HTMLInputElement).value.toDouble()

                    val sliderPercent = 0.5 + 0.3606737602 * ln(realPercent)

                    volumeSlider.value = sliderPercent.toString()
                }
            }
        }

        div("mt-12") {
            ActionButton(id = "adjust-volume-button", "Pump up the volume")
        }
    }

    // Due to probably a KotlinJS or Kotlin.HTML bug, the volumeSlider's "value =" property
    // does not work in the builder, as it directly sets the attribute. Though it only seems
    // to not work for slider inputs? The number input works ok.
    mainScope.launch {
        volumeSlider.value = "0.5"
    }
}
